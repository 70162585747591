var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "stamp-info" }, [
        _c("div", { staticClass: "stamp-info-top" }, [
          _c("div", { staticClass: "stamp-info-top-userInfo" }, [
            _c("img", { attrs: { src: this.case.avatarUrl, alt: "" } }),
            _c("div", [_vm._v(_vm._s(this.case.applicantName))]),
          ]),
          _c("div", { staticClass: "stamp-info-top-time" }, [
            _vm._v(_vm._s(this.case.useTimeText)),
          ]),
          this.case.progress === "UNSUBMENT"
            ? _c("div", { staticClass: "stamp-info-top-status" }, [
                _vm._v(" 未提交 "),
              ])
            : _vm._e(),
          this.case.progress === "UNAUDITED"
            ? _c("div", { staticClass: "stamp-info-top-status" }, [
                _vm._v(" 待审核 "),
              ])
            : _vm._e(),
          this.case.progress === "APPROVED"
            ? _c("div", { staticClass: "stamp-info-top-status" }, [
                _vm._v(" 审核通过 "),
              ])
            : _vm._e(),
          this.case.progress === "CERTIFIED_FAILED"
            ? _c("div", { staticClass: "stamp-info-top-status" }, [
                _vm._v(" 审核不通过 "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "stamp-info-line" }),
        _c("div", { staticClass: "stamp-info-center" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "pro-name" }, [
              _vm._v(_vm._s(this.case.stamperItem)),
            ]),
            _c("div", { staticClass: "type" }, [
              _vm._v(_vm._s(this.case.type)),
            ]),
          ]),
          this.case.category === "CASE"
            ? _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content-left" }, [
                  _vm._v("项目名称"),
                ]),
                _c("div", { staticClass: "content-right" }, [
                  _vm._v(_vm._s(this.case.itemName)),
                ]),
              ])
            : _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content-left" }, [_vm._v("份数")]),
                _c("div", { staticClass: "content-right" }, [
                  _vm._v(_vm._s(this.case.number)),
                ]),
              ]),
          this.case.category === "CASE"
            ? _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content-left" }, [
                  _vm._v("用章类型"),
                ]),
                _c("div", { staticClass: "content-right" }, [
                  _vm._v(_vm._s(this.case.type)),
                ]),
              ])
            : _vm._e(),
          this.case.category === "WORK"
            ? _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content-left" }, [_vm._v("收函方")]),
                this.case.recipient === ""
                  ? _c("div", { staticClass: "content-right" }, [
                      _vm._v(" 暂无 "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [{ name: "esle", rawName: "v-esle" }],
                    staticClass: "content-right",
                  },
                  [_vm._v(_vm._s(this.case.recipient))]
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content-left" }, [_vm._v("备注")]),
            this.case.remarks === ""
              ? _c("div", { staticClass: "content-right" }, [_vm._v("暂无")])
              : _c("div", { staticClass: "content-right" }, [
                  _vm._v(_vm._s(this.case.remarks)),
                ]),
          ]),
          _c(
            "div",
            { staticClass: "oa-stamp-image" },
            _vm._l(this.case.collections, function (items, i) {
              return _c("div", { key: i, staticClass: "oa-stamp-image-box" }, [
                items.type === "WORD"
                  ? _c("i", {
                      staticClass: "iconfont icon-word",
                      staticStyle: { color: "rgb(108, 168, 255)" },
                    })
                  : _vm._e(),
                items.type === "EXCEL"
                  ? _c("i", {
                      staticClass: "iconfont icon-exl",
                      staticStyle: { color: "rgb(139, 221, 108)" },
                    })
                  : _vm._e(),
                items.type === "PDF"
                  ? _c("i", {
                      staticClass: "iconfont icon-PDF",
                      staticStyle: { color: "rgb(62, 179, 240)" },
                    })
                  : _vm._e(),
                items.type === "VIDEO"
                  ? _c("i", {
                      staticClass: "iconfont icon-shipin",
                      staticStyle: { color: "rgb(75, 158, 251)" },
                    })
                  : _vm._e(),
                items.type === "AUDIO"
                  ? _c("i", {
                      staticClass: "iconfont icon-shipin",
                      staticStyle: { color: "rgb(75, 158, 251)" },
                    })
                  : _vm._e(),
                items.type === "IMAGE"
                  ? _c("img", {
                      staticClass: "oa-stamp-image-img",
                      attrs: { src: items.content, alt: "" },
                    })
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "stamp-info-line" }),
        _c(
          "div",
          { staticClass: "stamp-info-bottom" },
          [
            _c("span", { staticClass: "title" }, [_vm._v("审批记录")]),
            _c("approval-record", {
              attrs: { typeId: this.id, type: "STAMPER_RECORD_ADD" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "oa-button-view" }, [
        this.case.btnList.length === 2
          ? _c(
              "button",
              {
                staticClass: "oa-button-reject",
                on: {
                  click: function ($event) {
                    return _vm.approval("CERTIFIED_FAILED")
                  },
                },
              },
              [_vm._v(" 驳回 ")]
            )
          : _vm._e(),
        this.case.btnList.length === 2
          ? _c(
              "button",
              {
                staticClass: "oa-button-apply",
                on: {
                  click: function ($event) {
                    return _vm.approval("APPROVED")
                  },
                },
              },
              [_vm._v(" 批准 ")]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }