<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="back"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <!-- <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button> -->
    </fb-header>
    <div class="stamp-info">
      <div class="stamp-info-top">
        <div class="stamp-info-top-userInfo">
          <img :src="this.case.avatarUrl" alt="" />
          <div>{{ this.case.applicantName }}</div>
        </div>
        <div class="stamp-info-top-time">{{ this.case.useTimeText }}</div>
        <div
          class="stamp-info-top-status"
          v-if="this.case.progress === 'UNSUBMENT'"
        >
          未提交
        </div>
        <div
          class="stamp-info-top-status"
          v-if="this.case.progress === 'UNAUDITED'"
        >
          待审核
        </div>
        <div
          class="stamp-info-top-status"
          v-if="this.case.progress === 'APPROVED'"
        >
          审核通过
        </div>
        <div
          class="stamp-info-top-status"
          v-if="this.case.progress === 'CERTIFIED_FAILED'"
        >
          审核不通过
        </div>
      </div>
      <div class="stamp-info-line"></div>
      <div class="stamp-info-center">
        <div class="title">
          <div class="pro-name">{{ this.case.stamperItem }}</div>
          <div class="type">{{ this.case.type }}</div>
        </div>
        <div class="content" v-if="this.case.category === 'CASE'">
          <div class="content-left">项目名称</div>
          <div class="content-right">{{ this.case.itemName }}</div>
        </div>
        <div class="content" v-else>
          <div class="content-left">份数</div>
          <div class="content-right">{{ this.case.number }}</div>
        </div>
        <div class="content" v-if="this.case.category === 'CASE'">
          <div class="content-left">用章类型</div>
          <div class="content-right">{{ this.case.type }}</div>
        </div>
        <div class="content" v-if="this.case.category === 'WORK'">
          <div class="content-left">收函方</div>
          <div class="content-right" v-if="this.case.recipient === ''">
            暂无
          </div>
          <div class="content-right" v-esle>{{ this.case.recipient }}</div>
        </div>
        <div class="content">
          <div class="content-left">备注</div>
          <div class="content-right" v-if="this.case.remarks === ''">暂无</div>
          <div class="content-right" v-else>{{ this.case.remarks }}</div>
          <!-- <img src="https://d1icd6shlvmxi6.cloudfront.net/gsc/UQ0WVA/3f/ea/88/3fea888e25404ae5998b6e6c5c252214/images/%E4%B8%9A%E5%8A%A1%E7%94%A8%E7%AB%A0/u408.png?token=257ef6d8032a41232fea0948c890c2ca1a776147f32fce65e75d5a2a1d108d33"
               alt=""> -->
        </div>
        <div class="oa-stamp-image">
          <div
            class="oa-stamp-image-box"
            v-for="(items, i) in this.case.collections"
            :key="i"
          >
            <i
              v-if="items.type === 'WORD'"
              class="iconfont icon-word"
              style="color: rgb(108, 168, 255);"
            ></i>
            <i
              v-if="items.type === 'EXCEL'"
              class="iconfont icon-exl"
              style="color: rgb(139, 221, 108);"
            ></i>
            <i
              v-if="items.type === 'PDF'"
              class="iconfont icon-PDF"
              style="color: rgb(62, 179, 240);"
            ></i>
            <i
              v-if="items.type === 'VIDEO'"
              class="iconfont icon-shipin"
              style="color: rgb(75, 158, 251);"
            ></i>
            <i
              v-if="items.type === 'AUDIO'"
              class="iconfont icon-shipin"
              style="color: rgb(75, 158, 251);"
            ></i>
            <img
              v-if="items.type === 'IMAGE'"
              :src="items.content"
              alt=""
              class="oa-stamp-image-img"
            />
          </div>
        </div>
        <!-- <div class="content-img">
          <img src="https://d1icd6shlvmxi6.cloudfront.net/gsc/UQ0WVA/3f/ea/88/3fea888e25404ae5998b6e6c5c252214/images/%E7%9B%96%E7%AB%A0/u1460.png?token=f73baefadfa336ed13b859f9d87fabdb48bec17effc0a2ca9eb3b35f81f5b603"
               alt="">
        </div> -->
      </div>
      <div class="stamp-info-line"></div>
      <div class="stamp-info-bottom">
        <span class="title">审批记录</span>
        <approval-record :typeId="this.id" :type="'STAMPER_RECORD_ADD'" />
      </div>
    </div>
    <div class="oa-button-view">
      <!-- <button class="oa-button-save">评论</button> -->
      <button
        class="oa-button-reject"
        v-if="this.case.btnList.length === 2"
        @click="approval('CERTIFIED_FAILED')"
      >
        驳回
      </button>
      <button
        class="oa-button-apply"
        v-if="this.case.btnList.length === 2"
        @click="approval('APPROVED')"
      >
        批准
      </button>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState } from 'vuex'
import ApprovalRecord from '../../components/approvalRecord'
export default {
  name: 'stamp-info',
  mixins: [goBackMixin],
  components: {
    ApprovalRecord
  },
  data() {
    return {
      title: '案件用章',
      id: 0,
      case: {
        btnList: []
      } // 案件信息
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    })
  },
  methods: {
    back() {
      console.log('==========>')
      console.log(this.directGoNative)
      console.log(window.nativeEnterIndex)
      console.log(this.$router.length)
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    // 审批操作
    approval(progress) {
      const params = {
        approveRecordUsers: this.case.approveRecordUsers,
        category: this.case.category,
        collections: this.case.collections,
        progress: progress,
        recipient: this.case.recipient,
        remarks: this.case.remarks,
        stamperItem: this.case.stamperItem,
        type: this.case.type,
        useTime: new Date(this.case.useTime).getTime(),
        number: this.case.number
      }
      if (this.category === 'CASE') {
        delete params.number
      }
      this.$axios
        .put(
          `${this.$base}/management/stamper/record/${this.case.id}/progress`,
          params
        )
        .then(res => {
          if (res.data.code === 200) {
            this.$toast.success('审批成功')
            setTimeout(() => {
              this.back() // 返回上一层
            }, 1000)
          } else {
            this.$toast.fail('审批失败')
          }
        })
    }
  },
  async created() {
    this.id = this.$route.query.commonId
    const res = await this.$axios.get(
      `${this.$base}/management/stamper/record/${this.$route.query.commonId}`
    )
    const { code, data } = res.data
    if (code === 200) {
      let avatarUrl = ''
      data.approveRecordUsers.map((item, i) => {
        if (item.type === 'APPLICANT') {
          avatarUrl = item.user.avatarUrl
        }
      })
      data.avatarUrl = avatarUrl
      // 申请时间
      const useTime = new Date(data.useTime.replace(/-/g, '/')).getTime()
      data.useTimeText = this.getDateDiff(useTime)
      switch (data.progress) {
        case 'UNSUBMENT':
          // data['btnList'] = ['申请', '删除']
          data.btnList = []
          break
        case 'UNAUDITED':
          data.approveRecordUsers.forEach(item => {
            if (item.type === 'APPROVER') {
              if (item.user.id === this.userId) {
                data.btnList = ['批准', '驳回']
              } else {
                data.btnList = []
              }
            }
          })
          break
        case 'APPROVED':
          data.btnList = []
          break
        case 'CERTIFIED_FAILED':
          // data['btnList'] = ['申请', '删除']
          data.btnList = []
          break
      }
    } else {
      this.$notify('找不到数据')
    }
    this.case = data
  },
  mounted() {}
}
</script>

<style lang="stylus">
.stamp-info
  position relative
  padding-bottom 80px
  box-sizing border-box
  & .stamp-info-top
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    padding 10px 20px
    box-sizing border-box
    & .stamp-info-top-userInfo
      display flex
      flex-direction row
      align-items center
      font-size 16px
      img
        border-radius 15px
        width 30px
        height 30px
        margin-right 10px
    & .stamp-info-top-time
      font-size 16px
    & .stamp-info-top-status
      font-size 16px
      color #FF9900
  & .stamp-info-line
    width 100%
    height 6px
    background rgba(242, 242, 242, 1)
.stamp-info-center
  padding 0 20px 20px 20px
  box-sizing border-box
  & .title
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    padding 10px 0px
    box-sizing border-box
    font-size 16px
    & .pro-name
      font-size 18px
      font-weight 700
      flex 1
  & .content
    display flex
    flex-direction row
    justify-content space-between
    padding 5px 0px
    box-sizing border-box
    border-bottom 1px solid rgba(242, 242, 242, 1)
    & .content-left
      font-size 16px
      color #CCCCCC
      margin-right 10px
      width 80px
    & .content-right
      font-size 16px
      flex 1
  & .content-img
    padding 10px 0 30px 0
    box-sizing border-box
  & .oa-stamp-image
    display flex
    flex-direction row
    align-items center
    padding 20px 0
    box-sizing border-box
    flex-wrap wrap
    & .oa-stamp-image-box
      position relative
      width 70px
      height 70px
      margin-right 10px
      margin-bottom 10px
      & .iconfont
        font-size 70px
      & .oa-stamp-image-delete
        position absolute
        width 20px
        height 20px
        right -10px
        top -10px
      & .oa-stamp-image-img
        width 100%
        height 100%
        object-fit contain
.stamp-info-bottom
  padding 20px
  box-sizing border-box
  & .title
    font-size 12px
    color #CCCCCC
  & .approval
    margin-top 20px
    & .content
      display flex
      flex-direction row
      & .content-box
        border-left 2px solid #cccccc
        padding 0px 0px 40px 20px
        box-sizing border-box
        position relative
        left -14px
        z-index -1
        width 100%
      &:last-child
        & .content-box
          border-left 0 solid #cccccc
      img
        width 30px
        height 30px
        border-radius 15px
      & .top
        font-size 14px
      & .bottom
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        font-size 12px
        color #cccccc
        width 100%
        & .time
          color #000000
          margin-left 10px
.oa-button-view
  width 100%
  background #FFFFFF
  position fixed
  bottom 0px
  right 0px
  padding 20px
  box-sizing border-box
  display flex
  flex-direction row
  justify-content flex-end
  button
    width 80px
    height 50px
    border-radius 5px
    font-size 16px
  & .oa-button-save
    background none
    border none
    color #0079FE
  & .oa-button-apply
    background #0079FE
    border none
    color #FFFFFF
  & .oa-button-reject
    background none
    border 1px solid #0079FE
    color #0079FE
</style>
